import React from "react"
import { Box, Flex, Link, Tag, Text } from "@chakra-ui/core"
import { Link as NaviLink, useCurrentRoute } from "react-navi"
import {
    FaTrophy,
    FaStar,
    FaUserGraduate,
    FaBook,
    FaRegImages,
    FaUsers,
    FaSearch,
    FaRegStickyNote
} from "react-icons/fa"
import { WiLightning } from "react-icons/wi"
import useUser from "../store/user"

const navItems = [
    {
        name: "/cards",
        text: "Flashcards",
        icon: FaRegImages
    },
    {
        name: "/study",
        text: "Học",
        icon: FaBook
    },
    {
        name: "/note",
        text: "Ghi chú",
        icon: FaRegStickyNote
    },
    {
        name: "/follow",
        text: "Theo dõi",
        icon: FaUsers
    },
    {
        name: "/search",
        text: "Tìm kiếm",
        icon: FaSearch
    }
]

const active = {
    py: 2,
    px: 4,
    rounded: 4,
    bg: "purple.700",
    color: "purple.100"
}

const normal = {
    color: "gray.700"
}

export default () => {
    const user = useUser(store => store.user)
    const route = useCurrentRoute()

    return (
        <header>
            <Flex h={16} align="center">
                <Tag variantColor="orange" rounded="full">
                    <WiLightning />
                </Tag>
                <Flex flex={1} justify="space-around">
                    {navItems.map(item => (
                        <Link
                            key={item.name}
                            as={NaviLink}
                            href={item.name}
                            d="flex"
                            alignItems="center"
                            {...(route.url.pathname === item.name
                                ? active
                                : normal)}
                        >
                            <Box as={item.icon} mr={1} />
                            {item.text}
                        </Link>
                    ))}
                </Flex>
                <Flex w={256} justify="space-between" align="center">
                    <FaTrophy />
                    <FaStar />
                    <Flex align="center">
                        <FaUserGraduate />
                        <Text ml={1}>{user?.username}</Text>
                    </Flex>
                </Flex>
            </Flex>
        </header>
    )
}
