import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import ThemedApp from "./ThemedApp";
import { setUser } from "./store/user";
import { loadCardForm } from "./store/card"
import "./app.css";

setUser({ userId: 1, username: 'Vuhg' })
// load unfinished card form
loadCardForm()

ReactDOM.render(<ThemedApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
