import React, { Suspense } from "react"
import { lazy, mount, route } from "navi"
import { Router, View } from "react-navi"
import Home from "./pages/home"

// Define your routes
const routes = mount({
    "/": route({
        title: "Have fun learning!",
        view: <Home />
    }),
    "/create": lazy(() => import("./pages/create")),
    "/study": lazy(() => import("./pages/study")),
    "/cards": lazy(() => import("./pages/cards")),
})

export default () => (
    <Router routes={routes}>
        <Suspense fallback={null}>
            <View />
        </Suspense>
    </Router>
)
