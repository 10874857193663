import React from "react"
import { Box } from "@chakra-ui/core"
import TheHeader from "./TheHeader"

export default ({ children }) => (
	<Box w={1200} m="0 auto">
		<TheHeader />
		<Box mt={8}>{children}</Box>
	</Box>
)
