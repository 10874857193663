import createStore from "./"

const [useCard, api] = createStore(set => ({
    form: null,
    topics: [],
    cards: [],

    addCard: item => set(draft => void draft.cards.push(item)),

    updateCard: item =>
        set(draft => {
            const index = draft.cards.findIndex(i => i.id === item.id)
            draft.cards[index] = item
        }),

    deleteCard: id =>
        set(draft => {
            const index = draft.cards.findIndex(i => i.id === id)
            draft.cards.splice(index, 1)
        }),

    addTopic: item => set(draft => void draft.topics.push(item)),

    updateTopic: item =>
        set(draft => {
            const index = draft.topics.findIndex(i => i.id === item.id)
            draft.topics[index] = item
        }),

    deleteTopic: id =>
        set(draft => {
            const index = draft.topics.findIndex(i => i.id === id)
            draft.topics.splice(index)
        })
}))

export default useCard

export const loadCards = cards =>
    api.setState(state => ({
        ...state,
        cards
    }))

export const loadTopics = topics =>
    api.setState(state => ({
        ...state,
        topics
    }))


const defaultFormValues = {
    is_vertical: false,
    quiz: []
}

export const loadCardForm = () => {
    try {
        const rawCardForm = localStorage.getItem('cardForm')
        if (!rawCardForm) {
            api.setState(state => ({
                ...state,
                form: {...defaultFormValues}
            }))
            return
        }

        const cardForm = JSON.parse(rawCardForm)
        api.setState(state => ({
            ...state,
            form: cardForm
        }))
    } catch (e) {
        console.error(e)
    }
}

export const unloadCardForm = () => {
    localStorage.removeItem('cardForm')
}

export const persistCardForm = (form) => {
    localStorage.setItem('cardForm', JSON.stringify(form))
}
