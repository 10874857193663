import createStore from "./";

const [useUser, api] = createStore(set => ({
    user: null
}))

export default useUser

export const setUser = user => {
    api.setState({ user })
}