import React from "react";
import { ThemeProvider, CSSReset, theme } from "@chakra-ui/core";
import Router from "./Router";

const customTheme = {
    ...theme,
    colors: {
        ...theme.colors,
        background: '#fff8f0',
        primary: "#ff6b17",
        secondary: "#2F27CC",
        tertiary: "#53FF30"
    },
};

export default () => (
  <ThemeProvider theme={customTheme}>
    <CSSReset />
    <Router />
  </ThemeProvider>
);
